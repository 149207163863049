exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blank-page-js": () => import("./../../../src/pages/blank-page.js" /* webpackChunkName: "component---src-pages-blank-page-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-comparison-js": () => import("./../../../src/pages/comparison.js" /* webpackChunkName: "component---src-pages-comparison-js" */),
  "component---src-pages-connectors-js": () => import("./../../../src/pages/connectors.js" /* webpackChunkName: "component---src-pages-connectors-js" */),
  "component---src-pages-deployment-security-js": () => import("./../../../src/pages/deployment-security.js" /* webpackChunkName: "component---src-pages-deployment-security-js" */),
  "component---src-pages-fund-raised-news-js": () => import("./../../../src/pages/fund-raised-news.js" /* webpackChunkName: "component---src-pages-fund-raised-news-js" */),
  "component---src-pages-gdpr-compliance-js": () => import("./../../../src/pages/gdpr-compliance.js" /* webpackChunkName: "component---src-pages-gdpr-compliance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-india-dpdp-compliance-js": () => import("./../../../src/pages/india-dpdp-compliance.js" /* webpackChunkName: "component---src-pages-india-dpdp-compliance-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-a-demo-js": () => import("./../../../src/pages/request-a-demo.js" /* webpackChunkName: "component---src-pages-request-a-demo-js" */),
  "component---src-pages-resources-connect-js": () => import("./../../../src/pages/resources/connect.js" /* webpackChunkName: "component---src-pages-resources-connect-js" */),
  "component---src-pages-resources-deploy-js": () => import("./../../../src/pages/resources/deploy.js" /* webpackChunkName: "component---src-pages-resources-deploy-js" */),
  "component---src-pages-resources-faqs-js": () => import("./../../../src/pages/resources/faqs.js" /* webpackChunkName: "component---src-pages-resources-faqs-js" */),
  "component---src-pages-resources-faqs-test-packets-js": () => import("./../../../src/pages/resources/faqs/test-packets.js" /* webpackChunkName: "component---src-pages-resources-faqs-test-packets-js" */),
  "component---src-pages-resources-identify-js": () => import("./../../../src/pages/resources/identify.js" /* webpackChunkName: "component---src-pages-resources-identify-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-solutions-data-exfiltration-js": () => import("./../../../src/pages/solutions/data-exfiltration.js" /* webpackChunkName: "component---src-pages-solutions-data-exfiltration-js" */),
  "component---src-pages-solutions-data-sharing-js": () => import("./../../../src/pages/solutions/data-sharing.js" /* webpackChunkName: "component---src-pages-solutions-data-sharing-js" */),
  "component---src-pages-solutions-smart-data-catalog-js": () => import("./../../../src/pages/solutions/smart-data-catalog.js" /* webpackChunkName: "component---src-pages-solutions-smart-data-catalog-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-us-privacy-compliance-js": () => import("./../../../src/pages/us-privacy-compliance.js" /* webpackChunkName: "component---src-pages-us-privacy-compliance-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

